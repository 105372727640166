<template>
    <section class="content">
        <div class="card mb-0">
            <div class="card-body login-card-body">
                <div class="section-home p-3 mb-3">
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" ref="email" required v-model="form.email" placeholder="Email">
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                          </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="email" class="form-control" ref="email" required v-model="form.email" placeholder="Email">
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                          </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Pilih program</label>
                        <v-select :options="optProgram" v-model="form.program_id" :reduce="opt => opt.id" @input="calculate" class="form-control"/>
                    </div>
                    <div class="col-sm-12">
                        <div style="width: 100%;height: auto;background-color: #9D9D9D;color: white;font-weight: 500;font-size: initial;padding: 20px;border-radius: 4px;margin-bottom: 12px;">
                            Biaya : {{form.nilai_total}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { auth, authFetch } from "@/libs/hxcore";
    import moment from "moment";
    import vSelect from 'vue-select';
    import "vue-select/dist/vue-select.css";

    let idr = Intl.NumberFormat("en-ID", {
        style: "currency",
        currency: "IDR",
        useGrouping: true,
        maximumSignificantDigits: 3,
    });

    export default {
        name: 'CompleteRegistration',
        components: {
            vSelect,
        },
        data() {
            return {
                imgUrl: 'noimg',
                agenda: [],
                info: [],
                promotions: [],
                url: '',
                promotionGroups: [],
                info: [],
                form: {
                    email:'',
                    program_id: '',
                    nilai_total: ''
                },
                optProgram: [],
                optProgramDetail: [],
            };
        },
        created: function () {
            this.url = window.$apiUrl;
            var info = auth.user();
            if(info)this.info = info;
            authFetch('/masterdata/siswa_register/program')
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    this.optProgram = js.data;
                })
            authFetch('/masterdata/siswa_register/program_detail')
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    this.optProgramDetail = js.data;
                })
        },
        mounted: {

        },
        methods: {
            calculate: function(program) {
                this.form.nilai_total = idr.format(this.optProgramDetail[program])
            },
        }
    }
</script>
<style type="text/css" scoped>
    .section-home {
        background: #F3F3F3;
    }

    .section-home .card-body {
        min-height: 100px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 2rem;
    }
    .form-control {
            height: calc(2rem + 2px);
            border:0;
        }
</style>